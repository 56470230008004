import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// dynamic routes
const WhatsOn = () => import("./views/WhatsOn.vue");
const CinemaTimes = () => import("./views/Cinema/CinemaTimes.vue");
const CinemaPrices = () => import("./views/Cinema/CinemaPrices.vue");
const CinemaVenues = () => import("./views/Cinema/CinemaVenues.vue");
const FloorGuide = () => import("./views/FloorGuide.vue");
const WelcomeBack = () => import("./views/WelcomeBack");
const CallCentre = () => import("./views/CallCentre.vue");
const Index = () => import("./views/Index.vue");


export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "index",
      component: Index
    },
    {
      path: "/cinemas/prices",
      name: "prices",
      component: CinemaPrices,
    },
    {
      path: "/cinemas/times",
      name: "times",
      component: CinemaTimes,
    },
    {
      path: "/cinemas/venues",
      name: "venues",
      component: CinemaVenues,
    },
    {
      path: "/welcome",
      name: "welcome",
      component: WelcomeBack,
    },
    {
      path: "/floor-guide",
      name: "floor-guide",
      component: FloorGuide,
    },
    {      
      path: '/whats-on',
      name: 'whats-on',
      component: WhatsOn
    },
    {
      path: '/call-centre',
      name: 'call-centre',
      component: CallCentre
    },
    {
      path: '/legacy-plasma/:legacyView',
      name: 'legacy-plasma',
      component: WelcomeBack,
    }
  ]
})
