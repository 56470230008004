import Vue from 'vue'
import App from './App.vue'
import router from './router'

// support graphql
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink, concat } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'

// initiate graphql link
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_BARBICAN_GRAPH_URL,
  credentials: 'include'
})

// https://www.apollographql.com/docs/react/v2/networking/network-layer
const authLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: `Bearer ${process.env.VUE_APP_BARBICAN_GRAPH_TOKEN}` || null,
    }
  });

  return forward(operation);
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: concat(authLink, httpLink),
  cache: new InMemoryCache(),
  resolvers: {}
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

// install VueApollo for graphql
Vue.use(VueApollo);

Vue.config.productionTip = false

new Vue({
  router,
  apolloProvider,
  render: function (h) { return h(App) }
}).$mount('#app')
